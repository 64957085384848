import { useMutation } from "@apollo/client";
import { Actions, Flex, InputTextarea, SurfaceForm } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";
import { preventionAgencyServiceReferralsPath } from "routes";

import { translationWithRoot } from "@components/T";
import SearchableHumanInput from "@components/prevention/agency_service_referrals/form/SearchableHumanInput";
import ServiceAndRateInput from "@components/prevention/agency_service_referrals/form/ServiceAndRateInput";

import { CreateAgencyServiceReferral } from "@graphql/mutations/prevention/CreateAgencyServiceReferral.graphql";

import preventDefault from "@lib/preventDefault";

const { t } = translationWithRoot(
  "prevention.agency_service_referrals.form_page"
);
const AgencyServiceReferralsForm = ({ referringAgencyId }) => {
  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: {
      referringAgencyId,
      childrenWithPlans: [],
      receivingHumans: [],
      service: {},
      serviceRates: [],
    },
  });

  const [createReferral, { loading: creatingReferral, called, error }] =
    useMutation(CreateAgencyServiceReferral);

  if (called && !error) {
    // TODO(ENG-19563): Build actual page, right now this page doesn't exist
    window.location.assign(preventionAgencyServiceReferralsPath());
  }

  const onSubmit = preventDefault(() => {
    createReferral({
      variables: {
        receivingHumanIds: formState.receivingHumans.map(human => human.value),
        childrenWithPlanIds: formState.childrenWithPlans.map(
          child => child.value
        ),
        agencyServiceId: formState.service.value,
        agencyServiceRateIds: formState.serviceRates.map(rate => rate.value),
        notes: formState.notes,
        agencyId: referringAgencyId,
      },
    });
  });

  return (
    <SurfaceForm
      title={""}
      actions={
        <Actions isSubmitting={creatingReferral} primaryAction={onSubmit} />
      }
    >
      <Flex column fullWidth>
        <SearchableHumanInput
          onChange={humans => setFormAttribute("receivingHumans")(humans)}
          label={t("receiving_humans")}
          value={formState.receivingHumans}
          required
        />
        <SearchableHumanInput
          onChange={humans => setFormAttribute("childrenWithPlans")(humans)}
          label={t("children_with_plans")}
          filterForChildren
          value={formState.childrenWithPlans}
        />
        <ServiceAndRateInput
          onServiceChange={service => {
            setFormAttribute("service")(service);
            setFormAttribute("serviceRates")([]);
          }}
          onRateChange={service => setFormAttribute("serviceRates")(service)}
          selectedService={formState.service}
          selectedRates={formState.serviceRates}
        />
        <InputTextarea
          value={formState.notes}
          label={t("notes")}
          onChange={notes => setFormAttribute("notes")(notes)}
        />
      </Flex>
    </SurfaceForm>
  );
};

AgencyServiceReferralsForm.propTypes = {
  referringAgencyId: PropTypes.string.isRequired,
};

export default AgencyServiceReferralsForm;
