import { gql, useQuery } from "@apollo/client";
import { Flex } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";
import { applicationDocumentTranslatedFormPath } from "routes";

import { translationWithRoot } from "@components/T";

import useFeatureFlag from "@lib/useFeatureFlag";

const TranslatedLink = ({ doc }) => {
  const { flag: ffTranslationAi052024 } = useFeatureFlag(
    "ff_translation_ai_05_2024"
  );
  const { t } = translationWithRoot("application_requirements");
  const ApplicationQuery = gql`
    query Application($id: ID!) {
      application(id: $id) {
        applicantAgencyProfile {
          user {
            locale
          }
        }
      }
    }
  `;
  const { data: applicationData } = useQuery(ApplicationQuery, {
    variables: { id: doc.applicationId },
  });
  // get the locale for the primary applicant on the application as this locale sets
  // the language for the whole application
  const locale =
    applicationData?.application?.applicantAgencyProfile?.user?.locale;

  return (
    <If
      condition={
        ffTranslationAi052024 &&
        !["en", "es", null].includes(locale) &&
        doc.type === "ApplicationFormDocument" &&
        doc.category !== "signed_reference" &&
        !applicationData?.application?.cpaOrGroupCare
      }
    >
      <Flex column>
        <a
          href={applicationDocumentTranslatedFormPath({
            application_id: doc.applicationId,
            document_id: doc.id,
          })}
          target={`translated-form-${doc.id}`}
        >
          {t("download_translated_pdf")}
        </a>
      </Flex>
    </If>
  );
};

TranslatedLink.propTypes = {
  doc: PropTypes.object.isRequired,
};

export default TranslatedLink;
